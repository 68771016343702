import React from "react";
import { Link } from "gatsby";

import LogoMain from "../../assets/image/logos/face.svg";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      
      {/* STATIC LOGO */}
      {/* <img src={logoMain} alt="" /> */}
      
      {/* SVG LOGO */}
      <LogoMain />
    </Link>
  );
};

export default Logo;
