import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider'
import { CONTRACT_ADDRESS, DEPLOY_ENV, INFURA_ID, API_URL, MINT_PRICE, POLLING_TIMER_SECONDS } from '../constants'
import abiJsonYou from '../data/YouXScrye.json'

const PageContext = React.createContext();

function Web3PageProvider({ children }) {
  // useState funcs that will provide shareable vars to functions
  const [isInitialized, setIsInitialized] = useState(false)
  const [web3Modal, setWeb3Modal] = useState();
  const [provider, setProvider] = useState({});
  const [web3, setWeb3] = useState({});
  const [totalRemaining, setTotalRemaining] = useState(0)
  const [isWalletConnected, setConnected] = useState(false);
  const [contractInstance, setContract] = useState({});
  const [address, setAddress] = useState('');
  const [networkId, setNetworkId] = useState('');
  const [chainId, setChainId] = useState('');
  const [nftPrice, setPrice] = useState(MINT_PRICE);
  const [web3Error, setWeb3Error] = useState('')
  const [totalCollected, setTotalCollected] = useState(0)
  const [isSoldOut, setIsSoldOut] = useState(false)
  const [mintData, setMintData] = useState();
  const [youScanMetadata, setYouScanData] = useState();

  // INIT web3modal
  useEffect(() => {
    if (web3Modal) {
      return;
    }

    /**
     * Provide wallet options to web3modal:
     * See examples https://github.com/Web3Modal/web3modal/blob/master/docs/providers/walletconnect.md
     */
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: INFURA_ID, // required
        }
      }
    }

    const initModal = new Web3Modal({
      network: DEPLOY_ENV, // optional
      // network: NETWORK_CONFIG, // optional
      cacheProvider: DEPLOY_ENV === 'mainnet', // optional Don't cache for dev, want to see modal always
      providerOptions, // required
      // disableInjectedProvider: true,
    });
    setWeb3Modal(initModal)
  }, [setWeb3Modal, web3Modal])

  /**
   * This starts the polling API calls for totalRemaining out of minted.
   * Then it sets isInitialized
   * Don't use Infura API calls, because of API rate limits.
   */
  const apiGetCounter = useCallback(async () => {
    try {
      const _totalRemaining = await fetchJson(`${API_URL}/dropname/remaining`)
      if (!isNaN(Number(_totalRemaining))) {
        setTotalRemaining(_totalRemaining)
      }
    } catch (err) {
      console.error('fetching counter failed')
    }
  }, [setTotalRemaining])

  useEffect(() => {
    let intervalId
    if (!isInitialized) {
      setIsInitialized(true)
    }

    // apiGetCounter()
    //   .then(_success => {
    //     intervalId = setInterval(apiGetCounter, POLLING_TIMER_SECONDS * 1000)
    //   })
    //   .catch(e => {
    //     console.error("fetching counter failed")
    //   })

    return () => clearInterval(intervalId)
  }, [])

  const connectContract = useCallback((foundWeb3, abiJson) => {
    const contract = new foundWeb3.eth.Contract(abiJson.abi, CONTRACT_ADDRESS);
    if (!contract) {
      throw new Error('No contract found');
    }
    setContract(contract);
    setConnected(true);
    return contract;
  }, []);

  const getAddress = useCallback(
    async (foundWeb3) => {
      if (foundWeb3?.eth?.getAccounts) {
        const foundAccounts = await foundWeb3.eth.getAccounts();
        return foundAccounts[0];
      }

      if (web3?.eth?.getAccounts) {
        const foundAccounts = await web3.eth.getAccounts();
        return foundAccounts[0];
      }
      throw new Error('Could not get address');
    },
    [web3.eth]
  );

  const getNetworkId = useCallback(
    async (foundWeb3) => {
      if (window?.ethereum?.networkVersion) {
        return window.ethereum.networkVersion;
      }

      // Try the one passed in, and if not, then refer to the one set in state
      if (foundWeb3?.eth?.net?.getId) {
        return await foundWeb3.eth.net.getId();
      }

      if (web3?.eth?.net?.getId) {
        return await web3.eth.net.getId();
      }
      // Do we need a networkId absolutely, and if so then throw an error here...
    },
    [web3.eth]
  );

  const getChainId = useCallback(
    async (foundWeb3) => {
      if (window?.ethereum?.chainId) {
        return window.ethereum.chainId;
      }

      if (foundWeb3?.eth?.chainId) {
        return await foundWeb3.eth.chainId();
      }

      if (web3?.eth?.chainId) {
        return await web3.eth.chainId();
      }
      return '';
    },
    [web3.eth]
  );

  // This is triggered when person connects wallet
  const setupWeb3AndModal = useCallback(async () => {
    // DEV ONLY REMOVE WHEN LAUNCH!!!!!!!!!
    // setAddress('0x14dc79964da2c08b23698b3d3cc7ca32193d9955')
    // setConnected(true)
    // return
    // END DEV ONLY REMOVE WHEN LAUNCH!!!!!!!!!

    await web3Modal.clearCachedProvider();

    const providerToSet = await web3Modal.connect();

    /**
     *
     * TODO: add listeners needed for walletconnect transactions
     */
    async function subscribeToWeb3Modal() {
      providerToSet.on('close', () => {
        web3Modal.clearCachedProvider()
      })
    }

    await subscribeToWeb3Modal()
    await providerToSet.enable()
    // create and set web3
    const foundWeb3 = new Web3(providerToSet);
    if (!foundWeb3) {
      throw new Error('Issue setting web3 based on provider settings');
    }
    setWeb3(foundWeb3);

    const foundAddress = await getAddress(foundWeb3);
    const foundNetworkId = await getNetworkId(foundWeb3);
    const foundChainId = await getChainId(foundWeb3);

    // set everything in state
    setProvider(providerToSet);
    setAddress(foundAddress);
    setNetworkId(foundNetworkId);
    setChainId(foundChainId);

    // TODO start polling remaining items updates.

    // Have to return these bc timing issues
    return [foundWeb3, foundAddress];
  }, [web3Modal, getChainId, getNetworkId, getAddress]);

  // When a user clicks connect button in nav bar
  const handleWalletConnect = useCallback(async () => {
    try {
      const [foundWeb3, foundAddress] = await setupWeb3AndModal();

      // Start contract queries:
      const contract = connectContract(foundWeb3);

      // Remove network calls b/c INFURA API:
      // const totalSupply = await contract.methods.currentToken().call();
      // setTotalRemaining(8989 - (Number(totalSupply) - 1))

      // const _isWhitelisted = await contract.methods.allowlist(foundAddress).call();
      // setIsWhitelisted(_isWhitelisted);
      const balance = await contract.methods.balanceOf(foundAddress).call();
      setTotalCollected(balance);

      // // cache some tokenId data based on minted value above.
      // getTokenIds();
    } catch (error) {
      if (error === 'Modal closed by user') {
        return;
      }
      console.error(error);
    }
  }, [setupWeb3AndModal, connectContract]);

   /**
   * Call an endpoint that retreives metadata by user address -
   * since the Dapp doesn't know conveniently from contract what token integer ID the user owns.
   * TODO: Send user signature - API verifies signature so we reveal to an actual owner.
   */
  const handleGetYouScan = useCallback(async (_address) => {
    try {
      const scanData = await fetch(`${API_URL}/face/metadata-by-address/${_address}`)
        .then(data => data.json())

      if (!scanData) return;

      setYouScanData(scanData)

    } catch (handleGetYouScanErr) {
      console.error(handleGetYouScanErr)
    }
    /**
     * Because of the timing issues with web3modal,
     * this dependency var isn't actually available in time. TODO, fix timing issues someday.
     */
  }, [address])

  // When a user clicks connect button in nav bar
  const handleWalletConnectForYouDrop = useCallback(async () => {
    try {
      const [foundWeb3, foundAddress] = await setupWeb3AndModal();

      // Start contract queries:
      connectContract(foundWeb3, abiJsonYou);

      await handleGetYouScan(foundAddress)

    } catch (error) {
      if (error === 'Modal closed by user') {
        return;
      }
      console.error(error);
    }

  }, [setupWeb3AndModal, connectContract]);

  /**
   * after a mint from fairPrice Or Dice, this is all the context that needs to update
   */
  const postMintHandler = useCallback(
    async (txRes) => {
      console.log(txRes);
      const balance = await contractInstance.methods.balanceOf(address).call();
      setTotalCollected(balance);
      // const totalSupply = await contractInstance.methods.currentToken().call();
      // setTotalRemaining(8989 - (Number(totalSupply) -1))

      // Do multiple events come from this though with same name?
      let event = txRes.events.Transfer;
      let allEvents = []
      if (event.length) {
        allEvents = event;
      } else {
        allEvents = [event]
      }
      console.log(allEvents)
      let data = []
      for (const e of allEvents) {
        const { tokenId } = e.returnValues;
        try {
          const newTokenMetadata = await fetchJson(`${API_URL}/you/metadata/${tokenId}`);
          data.push({ tokenId, ...newTokenMetadata })
        } catch (err) {
          data.push({ tokenId })
        }
      }
      setMintData(data)
      // return {
      //   txHash: txRes.transactionHash,
      //   metadata: { tokenId, ...newTokenMetadata },
      // };
    }, [contractInstance, address]
  )

  const handleBatchMint = useCallback(async(totalToMint) => {
    // DEV ONLY REMOVE WHEN LAUNCH!!!!!!!!!
    // setMintData([{ tokenId: 16}])
    // return
    // DEV ONLY REMOVE WHEN LAUNCH!!!!!!!!!

    if (process.env.GATSBY_ALLOWLIST_ONLY) {
      // Get merkle proof (has to be API)
      let hexProof = []
      try {
        hexProof = await fetchJson(`${API_URL}/dropname/whitelist/${address}`)
      } catch (whitelistErr) {
        setWeb3Error('There was an error checking your address on the allow list')
        return
      }

      if (!hexProof?.length) {
        setWeb3Error('Your address is not on the list for the presale.')
        return
      }

      // Contract mint
      try {
        const txRes = await contractInstance.methods.wlMint(totalToMint, hexProof).send({
          from: address,
          value: (web3.utils.toWei(nftPrice) * totalToMint).toString(),
        });

        // TODO implement TX rejected callback!
        return postMintHandler(txRes);
      } catch (mintErr) {
        console.error(mintErr)
        setWeb3Error('Error minting your token. Check wallet')
      }
      return
    }


    try {
      const txRes = await contractInstance.methods.batchMint(totalToMint).send({
        from: address,
        value: (web3.utils.toWei(nftPrice) * totalToMint).toString(),
      });

      // TODO implement TX rejected callback!
      return postMintHandler(txRes);
    } catch (mintErr) {
      console.error(mintErr)
      setWeb3Error('Error minting your token. Check wallet')
    }
  }, [web3, contractInstance, address, nftPrice, postMintHandler]);

  const state = {
    address,
    isInitialized, isSoldOut, isWalletConnected,
    mintData,
    totalCollected, totalRemaining,
    web3Error,
    youScanMetadata,
  }
  const handlers = {
    handleWalletConnectForYouDrop,
    handleGetYouScan,
    handleBatchMint, handleWalletConnect,
  }

  const value = useMemo(() => ({ ...state, ...handlers }), [state, handlers]);
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export async function fetchJson(url, options) {
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options || {},
  }).then((response) => response.json());
}


function usePageContext() {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error('usePageContext must be used within a PageProvider')
  }
  return context
}

export { Web3PageProvider, usePageContext }
