export const DEPLOY_ENV = process.env.GATSBY_DEPLOY_ENV;
export const INFURA_ID = process.env.GATSBY_INFURA_ID;
export const CONTRACT_ADDRESS = process.env.GATSBY_CONTRACT_ADDRESS || (DEPLOY_ENV === 'mainnet'
    ? '' : '');
export const API_URL = 'https://api.scrye.ai/v1'
export const TWITTER_URL = ''
export const DISCORD_URL = ''
export const IS_MINTING_LIVE = DEPLOY_ENV === 'mainnet' ? process.env.GATSBY_IS_MINTING === 'true' : true

export const MINT_PRICE = '0.04'
export const MAX_ALLOWED_TO_MINT = 10
export const NFT_NAME = 'A Scrye NFT'
export const MAX_SUPPLY = 1000

export const POLLING_TIMER_SECONDS = 30

export const chainsConfig = {
  localhost: {
    name: 'localhost',
    chain: 'ETH',
    network: 'mainnet',
    chain_id: 13317,
    network_id: 13317,
    rpc_url: 'http://localhost:8545',
  },
  mainnet:  {
    name: 'Ethereum Mainnet',
    short_name: 'eth',
    chain: 'ETH',
    network: 'mainnet',
    chain_id: 1,
    network_id: 1,
    rpc_url: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  ropsten: {
    name: 'Ethereum Ropsten',
    short_name: 'rop',
    chain: 'ETH',
    network: 'ropsten',
    chain_id: 3,
    network_id: 3,
    rpc_url: 'https://ropsten.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  rinkeby: {
    name: 'Ethereum Rinkeby',
    short_name: 'rin',
    chain: 'ETH',
    network: 'rinkeby',
    chain_id: 4,
    network_id: 4,
    rpc_url: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  goerli: {
    name: 'Ethereum Görli',
    short_name: 'gor',
    chain: 'ETH',
    network: 'goerli',
    chain_id: 5,
    network_id: 5,
    rpc_url: `https://goerli.infura.io/v3/${INFURA_ID}`,
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
};

export const NETWORK_CONFIG = chainsConfig[DEPLOY_ENV]
