import { rgba } from "polished";
import { breakpoints } from "./breakpoints";

// GOAT : Colors for styling
const goatColors = {
  primary: '#000000',
  secondary: '#b0a3a1',
  accent: '#6100ca',
  accentAlt: '#241a38',
  text: '#fff',
  textAlt: '#8b8b8b',
  textBg: '#453f71',
  background: '#000000',
  black: '#000000',

  // PANELS
  panelBg: '#000000',
  panelBorder: '#f9f8e3',
  panelTitle: '#993bff',
  panelTitleShadow: '#250c4e',
  panelText: '#f9f8e3',
  panelTextBg: '#453f71',
  panelSubtext: '#8b8b8b',
  panelButtonBg: '#341560',
  panelButtonBorder: '1px solid #6b01bb',
  panelButtonText: '#ffffff',
  panelButtonShadow: '7px 7px 0px #240938',

  headerBg: 'transparent',
  headerText: '#ffffff',
  headerBgScroll: `#000000`,
  headerTextScroll: `#ffffff`,

  // Can swap to these, not wired to mint button but just change luxColors to this
  mintButtonBg: '#613CB0',
  mintButtonBorder: `transparent`,
  mintButtonText: `#FFFFFF`
}




// GATSBY TEMPLATES - Can leave these as is for demo purposes
const defaultColors = {
  primary: "#723eab",
  secondary: "#5454d4",
  white: "#ffffff",
  dark: "#19191b",
  black: "#000000",
  yellow: "#fedc5a",
  ash: "#413e65",
  green: "#77bf41",
  info: "#0e567c",
};

const colors = {
  primary: defaultColors.primary,
  secondary: defaultColors.secondary,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.75),
  dark: defaultColors.dark,
  darkShade: rgba(defaultColors.dark, 0.75),
  ash: defaultColors.ash,
  bg: defaultColors.white,
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  text: rgba(defaultColors.dark, 0.75),
  warning: defaultColors.yellow,
  success: defaultColors.green,
  info: defaultColors.info,

  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.75),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.75),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.75),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
    },
  },
};

// // LUX : Ignore this, nothing to see here, just extra color fuckery
const luxColors = {
  blue: '#12ffee',
  purple: '#9509ff',
  pink: '#ff00e9',
  yellow: '#ffe500',
  green: '#00FF00',
  dark: '#111111'
};


const theme = {
  initialColorModeName: "light",
  colors: colors,
  goatColors,
  luxColors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
};

export default theme;
