export const menuItems = [
  {
    name: "#",
    label: "Home"
  },
  {
    name: "you",
    label: "You"
  },
  // {
  //   name: "mint",
  //   label: "IRV"
  // },
];
