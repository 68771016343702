// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-templates-blog-details-js": () => import("./../../../src/pages/templates/blog-details.js" /* webpackChunkName: "component---src-pages-templates-blog-details-js" */),
  "component---src-pages-templates-blog-left-sidebar-js": () => import("./../../../src/pages/templates/blog-left-sidebar.js" /* webpackChunkName: "component---src-pages-templates-blog-left-sidebar-js" */),
  "component---src-pages-templates-blog-regular-js": () => import("./../../../src/pages/templates/blog-regular.js" /* webpackChunkName: "component---src-pages-templates-blog-regular-js" */),
  "component---src-pages-templates-blog-right-sidebar-js": () => import("./../../../src/pages/templates/blog-right-sidebar.js" /* webpackChunkName: "component---src-pages-templates-blog-right-sidebar-js" */),
  "component---src-pages-templates-career-details-js": () => import("./../../../src/pages/templates/career-details.js" /* webpackChunkName: "component---src-pages-templates-career-details-js" */),
  "component---src-pages-templates-career-js": () => import("./../../../src/pages/templates/career.js" /* webpackChunkName: "component---src-pages-templates-career-js" */),
  "component---src-pages-templates-case-study-details-js": () => import("./../../../src/pages/templates/case-study-details.js" /* webpackChunkName: "component---src-pages-templates-case-study-details-js" */),
  "component---src-pages-templates-case-study-js": () => import("./../../../src/pages/templates/case-study.js" /* webpackChunkName: "component---src-pages-templates-case-study-js" */),
  "component---src-pages-templates-coming-soon-2-js": () => import("./../../../src/pages/templates/coming-soon-2.js" /* webpackChunkName: "component---src-pages-templates-coming-soon-2-js" */),
  "component---src-pages-templates-coming-soon-js": () => import("./../../../src/pages/templates/coming-soon.js" /* webpackChunkName: "component---src-pages-templates-coming-soon-js" */),
  "component---src-pages-templates-contact-1-js": () => import("./../../../src/pages/templates/contact-1.js" /* webpackChunkName: "component---src-pages-templates-contact-1-js" */),
  "component---src-pages-templates-contact-2-js": () => import("./../../../src/pages/templates/contact-2.js" /* webpackChunkName: "component---src-pages-templates-contact-2-js" */),
  "component---src-pages-templates-contact-3-js": () => import("./../../../src/pages/templates/contact-3.js" /* webpackChunkName: "component---src-pages-templates-contact-3-js" */),
  "component---src-pages-templates-faq-js": () => import("./../../../src/pages/templates/faq.js" /* webpackChunkName: "component---src-pages-templates-faq-js" */),
  "component---src-pages-templates-landing-2-js": () => import("./../../../src/pages/templates/landing-2.js" /* webpackChunkName: "component---src-pages-templates-landing-2-js" */),
  "component---src-pages-templates-landing-3-js": () => import("./../../../src/pages/templates/landing-3.js" /* webpackChunkName: "component---src-pages-templates-landing-3-js" */),
  "component---src-pages-templates-landing-4-js": () => import("./../../../src/pages/templates/landing-4.js" /* webpackChunkName: "component---src-pages-templates-landing-4-js" */),
  "component---src-pages-templates-landing-5-js": () => import("./../../../src/pages/templates/landing-5.js" /* webpackChunkName: "component---src-pages-templates-landing-5-js" */),
  "component---src-pages-templates-landing-6-js": () => import("./../../../src/pages/templates/landing-6.js" /* webpackChunkName: "component---src-pages-templates-landing-6-js" */),
  "component---src-pages-templates-landing-7-js": () => import("./../../../src/pages/templates/landing-7.js" /* webpackChunkName: "component---src-pages-templates-landing-7-js" */),
  "component---src-pages-templates-landing-8-js": () => import("./../../../src/pages/templates/landing-8.js" /* webpackChunkName: "component---src-pages-templates-landing-8-js" */),
  "component---src-pages-templates-pricing-1-js": () => import("./../../../src/pages/templates/pricing-1.js" /* webpackChunkName: "component---src-pages-templates-pricing-1-js" */),
  "component---src-pages-templates-pricing-2-js": () => import("./../../../src/pages/templates/pricing-2.js" /* webpackChunkName: "component---src-pages-templates-pricing-2-js" */),
  "component---src-pages-templates-reset-pass-bg-js": () => import("./../../../src/pages/templates/reset-pass-bg.js" /* webpackChunkName: "component---src-pages-templates-reset-pass-bg-js" */),
  "component---src-pages-templates-reset-pass-js": () => import("./../../../src/pages/templates/reset-pass.js" /* webpackChunkName: "component---src-pages-templates-reset-pass-js" */),
  "component---src-pages-templates-reviews-js": () => import("./../../../src/pages/templates/reviews.js" /* webpackChunkName: "component---src-pages-templates-reviews-js" */),
  "component---src-pages-templates-roadmap-js": () => import("./../../../src/pages/templates/roadmap.js" /* webpackChunkName: "component---src-pages-templates-roadmap-js" */),
  "component---src-pages-templates-signin-bg-js": () => import("./../../../src/pages/templates/signin-bg.js" /* webpackChunkName: "component---src-pages-templates-signin-bg-js" */),
  "component---src-pages-templates-signin-js": () => import("./../../../src/pages/templates/signin.js" /* webpackChunkName: "component---src-pages-templates-signin-js" */),
  "component---src-pages-templates-signup-bg-js": () => import("./../../../src/pages/templates/signup-bg.js" /* webpackChunkName: "component---src-pages-templates-signup-bg-js" */),
  "component---src-pages-templates-signup-js": () => import("./../../../src/pages/templates/signup.js" /* webpackChunkName: "component---src-pages-templates-signup-js" */),
  "component---src-pages-templates-terms-conditions-js": () => import("./../../../src/pages/templates/terms-conditions.js" /* webpackChunkName: "component---src-pages-templates-terms-conditions-js" */),
  "component---src-pages-templates-thank-you-js": () => import("./../../../src/pages/templates/thank-you.js" /* webpackChunkName: "component---src-pages-templates-thank-you-js" */),
  "component---src-pages-templates-the-team-js": () => import("./../../../src/pages/templates/the-team.js" /* webpackChunkName: "component---src-pages-templates-the-team-js" */),
  "component---src-pages-you-js": () => import("./../../../src/pages/you.js" /* webpackChunkName: "component---src-pages-you-js" */)
}

